import React, { useRef } from 'react';
import useReadingTime from 'use-reading-time';
import { Blockquote } from 'patterns/Blockquote';
import { Content } from 'patterns/Content';
import { Image } from 'patterns/Image';
import { MastheadArticle } from 'patterns/MastheadArticle';
import { Shell } from 'patterns/Shell';

import '../../scss/main.scss';

function ArticlePage1({
    location: {
        pathname,
    },
}) {
    const article = useRef();
    const { readingTime } = useReadingTime(article);

    return (
        <Shell
            pageDescription="From the 7th September, I’ll be available for design and development projects on a full-time basis."
            pageSocialImage=""
            pageTitle="Starting a new chapter as a full-time freelancer"
            pathname={pathname}
        >
            <MastheadArticle
                authorImage={{
                    alt: '',
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: 'https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format'
                }}
                authorName="Rob Simpson"
                date="16 Aug, 2020"
                image={{
                    alt: '',
                    dimensions: {
                        height: 3024,
                        width: 4032,
                    },
                    url: 'https://images.prismic.io/robsimpson/b654c40f-f586-4b69-8fdb-9ff3018fa444_about-office.jpg?auto=compress,format'
                }}
                readTime={readingTime}
                title="Starting a new chapter as a full time freelancer"
            />
            <div className="container" ref={article}>
                <Content
                    content={[
                        {spans: [], text: "For the past 7 years, I’ve been doing freelance projects on the side.", type: "paragraph"},
                        {spans: [], text: "At the back of my mind, I’ve always had this thought of one day taking that final step into full-time freelancing. The problem, I was holding out for the perfect time to take that leap.", type: "paragraph"},
                        {spans: [], text: "As the years have passed, however, I realised waiting for the perfect moment would likely never come – there will always be things to do or improve.", type: "paragraph"},
                        {spans: [], text: "Around 2 weeks ago, it became ever more apparent how finite time really is and if I’m going to spend so much of my day working, then I should spend that time working towards my own goals.", type: "paragraph"},
                        {spans: [], text: "Somewhat unknowingly, my wife and I had saved up enough money to keep us going for almost 6 months if I didn’t land a single project. On top of this, I had a surge of freelance project enquiries come in.", type: "paragraph"},
                        {spans: [], text: "All of these things combined made me realise, if there was ever a sign to take that leap, this was it.", type: "paragraph"},
                    ]}
                />
                <Blockquote
                    quote="From the 7th September, I’ll be available for design and development projects on a full-time basis"
                />
                <Content
                    content={[
                        {spans: [], text: "What do I do?", type: "heading2"},
                        {spans: [], text: "For anyone who doesn’t know what I do, I’m a UI/UX designer and front-end developer.", type: "paragraph"},
                        {spans: [], text: "My focus is on designing and building custom digital products and JAMstack websites.", type: "paragraph"},
                        {spans: [], text: "An overview of what this might include:", type: "paragraph"},
                        {spans: [], text: "👨🏻‍💻 Jamstack websites", type: "list-item"},
                        {spans: [], text: "🔍 Wireframing", type: "list-item"},
                        {spans: [], text: "🎨 Web and product design", type: "list-item"},
                        {spans: [], text: "🗺 Styleguides", type: "list-item"},
                        {spans: [], text: "🧩 Pattern libraries", type: "list-item"},
                        {spans: [], text: "🍿 UI animation", type: "list-item"},
                        {spans: [], text: "❤️ Accessibility optimisation", type: "list-item"},
                        {spans: [], text: "🚀 Performance optimisation", type: "list-item"},
                        {spans: [{
                            start: 103,
                            end: 127,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "mailto:hello@robsimpson.digital",
                            },
                        }], text: "If you need help with any of the above I’d love to hear from you. You can send me an email directly to hello@robsimpson.digital.", type: "paragraph"},
                        {spans: [], text: "👨🏻‍💻 Jamstack websites", type: "heading2"},
                        {spans: [], text: "For many sites, all you want is an easy to use CMS. Prismic and DatoCMS have incredibly intuitive interfaces, making it easy for content editors to pick up.", type: "paragraph"},
                        {spans: [], text: "My stack of choice for static marketing websites is to use Gatsby with a headless CMS like Prismic or DatoCMS, deployed to Netlify. Some key advantages you get for using a stack like this are:", type: "paragraph"},
                        {spans: [{
                            start: 0,
                            end: 23,
                            type: "strong",
                        }], text: "🚀 Improved performance as the content is built into pages at build time and not when a user visits the page", type: "list-item"},
                        {spans: [{
                            start: 0,
                            end: 20,
                            type: "strong",
                        }], text: "🛡 Improved security as your site is essentially a bunch of static pages; meaning your surface area for attack is greatly reduced", type: "list-item"},
                        {spans: [{
                            start: 0,
                            end: 18,
                            type: "strong",
                        }], text: "💰 Cheaper hosting as your site is a collection of static pages served through a CDN", type: "list-item"},
                    ]}
                />
                <Image
                    caption="Prismic content modelling for an image component"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 902,
                            width: 1203,
                        },
                        url: 'https://images.prismic.io/robsimpson/1da552fe-5053-42ea-84ee-18ba0ac9b592_prismic-content-modelling.webp?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "🔍 Wireframing", type: "heading2"},
                        {spans: [], text: "Wireframing is a key part of any project, it helps to put the focus on the content hierarchy before getting bogged down with aesthetics. I always do lo-fi wireframes, followed by hi-fi wireframes in Figma.", type: "paragraph"},
                        {spans: [], text: "These hi-fi wireframes are then turned into a clickable prototype so people can see exactly how things will work – it’s much cheaper and easier to catch issues early on.", type: "paragraph"},
                    ]}
                />
                <Image
                    caption="Hi-fidelity wireframe for a mobile food app designed in Figma"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 902,
                            width: 1203,
                        },
                        url: 'https://images.prismic.io/robsimpson/55969b57-371b-4476-bf33-42b6e25c72fe_food-app-hi-fi-wireframe.webp?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "🎨 Web and product design", type: "heading2"},
                        {spans: [], text: "I then use these hi-fi wireframes and begin to style them up. I like to share ideas early and often so there is no grand unveiling at the end – which would likely leave you feeling blindsided.", type: "paragraph"},
                    ]}
                />
                <Image
                    caption="Mobile web app designs for Harwell Hub"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 902,
                            width: 1203,
                        },
                        url: 'https://images.prismic.io/robsimpson/9b830f79-fb03-4380-8698-196981e70209_web-product-design.webp?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "🗺 Styleguides", type: "heading2"},
                        {spans: [], text: "During the design phase, I’ll also create a style guide which includes the base styling for the project – colours, typography, icons etc. This helps to keep a project consistent as it scales and provides a blueprint for developers when building the project.", type: "paragraph"},
                    ]}
                />
                <Image
                    caption="Styleguide containing an accessible colour palette to be used in an interface"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 902,
                            width: 1203,
                        },
                        url: 'https://images.prismic.io/robsimpson/b1d32273-a199-424f-8fb2-735987235e12_boilerplate-styleguide-colours.webp?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "🧩 Pattern libraries", type: "heading2"},
                        {spans: [], text: "Gone are the days of building restrictive page templates, it’s far more efficient to build flexible component-driven interfaces. This makes the interface far more scalable, as features can be added, removed and moved around with far less effort.", type: "paragraph"},
                        {spans: [], text: "It also means you can scale to thousands of pages with just a small number of components.", type: "paragraph"},
                    ]}
                />
                <Image
                    caption="Atomic design pattern library built in Storybook"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 902,
                            width: 1203,
                        },
                        url: 'https://images.prismic.io/robsimpson/7f25af76-6fb8-4fc2-ae23-9ccfed3b1872_storybook-pattern-library.webp?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "🍿 UI animation", type: "heading2"},
                        {spans: [], text: "Micro-interactions are becoming expected in today’s interfaces. Plus, it has some real benefits when it comes to the user experience and can reduce cognitive load for users when done right.", type: "paragraph"},
                    ]}
                />
                <Content
                    content={[
                        {spans: [], text: "❤️ Accessibility optimisation", type: "heading2"},
                        {spans: [], text: "The biggest misconception people have when they hear the term accessibility is that it only benefits a small number of people.", type: "paragraph"},
                        {spans: [], text: "However, government statistics show that roughly one in five people from the UK have reported suffering from some form of disability, whether visual, auditory, motor or cognitive.", type: "paragraph"},
                        {spans: [], text: "Viewed from a business perspective, it just doesn’t make sense to ignore upto 20% of the market share.", type: "paragraph"},
                    ]}
                />
                <Image
                    caption="Accessibility testing of de Pastory website using tota11y"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 902,
                            width: 1203,
                        },
                        url: 'https://images.prismic.io/robsimpson/0e33bd67-6ed3-4b29-8bb4-5582012f17df_depastory-a11y-testing.webp?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "🚀 Performance optimisation", type: "heading2"},
                        {spans: [], text: "There are several reasons to focus on performance, ranging from better SEO, lower bounce rates and improved user experience — all of which results in more leads and revenue.", type: "paragraph"},
                    ]}
                />
                <Image
                    caption="Performance testing of David Sewells website using page speed insights"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 902,
                            width: 1203,
                        },
                        url: 'https://images.prismic.io/robsimpson/6b31c944-a36a-495b-8bf4-7e481d8546a0_david-sewell-page-speed-score.webp?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "📬 I’d love to hear from you", type: "heading2"},
                        {spans: [{
                            start: 100,
                            end: 124,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "mailto:hello@robsimpson.digital",
                            },
                        }], text: "That’s it in a nutshell if you need help with any of the above you can send me an email directly to hello@robsimpson.digital, I hope and look forward to hearing from you.", type: "paragraph"},
                    ]}
                />
            </div>
        </Shell>
    );
}

export default ArticlePage1;
